/**
 *
 * Navbar
 *
 */

import React from "react"
import { createStructuredSelector } from "reselect"
import { compose } from "redux"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import styled from "styled-components"
import { push } from "connected-react-router/immutable"

import injectReducer from "organizers/utils/injectReducer"
import makeSelectSidebar from "./selectors"
import reducer from "./reducer"
import { makeSelectUser } from "common/selectors"
import Divider from "@material-ui/core/Divider"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Avatar from "@material-ui/core/Avatar"
import bftLogo from "common/images/bft-logo-black-medium.png"
import { closeSidebar } from "organizers/components/Navbar/actions"
import _ from "lodash"
import { withApollo } from "@apollo/client/react/hoc"
import { logout } from "common/auth/actions"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import EmailIcon from "@material-ui/icons/Email"
import HomeIcon from "@material-ui/icons/Home"
import AttachMoneyIcon from "@material-ui/icons/AttachMoney"
import AccountBoxIcon from "@material-ui/icons/AccountBox"
import SettingsIcon from "@material-ui/icons/SettingsOutlined"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import LotIcon from "@material-ui/icons/PictureInPicture"
import ShiftSalesIcon from "@material-ui/icons/MonetizationOn"
import ReportsIcon from "@material-ui/icons/Assessment"
import MonitoringIcon from "@material-ui/icons/ViewList"
import RestaurantIcon from "@material-ui/icons/Restaurant"
import ChatIcon from "@material-ui/icons/Chat"
import {
  hasCateringAccess,
  hasEventsAccess,
  hasOrderMonitoringAccess,
  hasPaymentAccess,
  hasReportsAccess,
  hasSalesAccess
} from "../../organizerRoles"

const Header = styled.div`
  min-height: 64px;
  ${({ theme }) => theme.media.md`min-height: 56px`};
`
const HeaderImg = styled.img`
  height: 56px;
  width: 240px;
`

const StyledListItem = styled(ListItem)`
  ${({ current }) =>
    current === "true" && "background-color: rgba(0, 0, 0, 0.12) !important;"};
`

const PAGES = [
  {
    name: "Log In",
    link: "/login",
    noSession: true,
    matches: [/^\/login$/],
    icon: <AccountBoxIcon />
  },
  {
    name: "Home",
    link: "/",
    matches: [/^\/$/, /^\/schedule$/],
    icon: <HomeIcon />
  },
  {
    name: "Catering",
    link: "/catering-requests",
    municipality: false,
    matches: [/^\/catering-requests$/],
    icon: <RestaurantIcon />,
    show: (user) => hasCateringAccess(user)
  },
  {
    name: "Mobile Inspections",
    link: "/mobile-inspections",
    municipality: true,
    matches: [/^\/mobile-inspections$/],
    icon: <RestaurantIcon />
  },
  {
    name: "Emails",
    municipality: false,
    link: "/support-conversations",
    matches: [/^\/support-conversations.*$/],
    icon: <EmailIcon />,
    show: (user) => hasCateringAccess(user)
  },
  {
    name: "Chats",
    link: "/chat-conversations",
    municipality: false,
    matches: [/^\/chat-conversations.*$/],
    icon: <ChatIcon />,
    show: (user) => hasCateringAccess(user)
  },
  {
    name: "Order Monitoring",
    link: "/monitoring",
    municipality: false,
    matches: [/^\/monitoring$/],
    icon: <MonitoringIcon />,
    show: (user) => hasOrderMonitoringAccess(user)
  },
  {
    name: "Lots",
    link: "/lots",
    municipality: false,
    matches: [/^\/lots$/],
    testName: "lots-sidebar",
    icon: <LotIcon />,
    show: (user) => hasEventsAccess(user)
  },
  {
    name: "Shift Sales",
    link: "/shift-sales",
    municipality: false,
    matches: [/^\/shift-sales$/],
    icon: <ShiftSalesIcon />,
    show: (user) => hasSalesAccess(user)
  },
  {
    name: "Reports",
    link: "/reports",
    municipality: false,
    matches: [/^\/reports.*/],
    icon: <ReportsIcon />,
    show: (user) => hasReportsAccess(user)
  },
  {
    name: "Settings",
    link: "/settings",
    matches: [/^\/settings.*/],
    icon: <SettingsIcon />,
    show: (user) => hasEventsAccess(user) || hasCateringAccess(user)
  },
  {
    name: "Payment Setup",
    link: "/payments",
    municipality: false,
    matches: [/^\/payments.*/],
    icon: <AttachMoneyIcon />,
    show: (user) => hasPaymentAccess(user)
  },
  {
    name: "Log Out",
    action: "logout",
    matches: [],
    icon: <ExitToAppIcon />
  }
]

class Sidebar extends React.Component {
  isActive = (page, path) => !!_.find(page.matches, (match) => match.test(path))

  makeGoToLink = (page) => () => {
    const { dispatch, client } = this.props
    if (page.link) {
      dispatch(push(page.link))
    } else if (page.action === "logout") {
      client.resetStore()
      dispatch(logout())
    } else {
      throw new Error("Bad Sidebar Item")
    }
    dispatch(closeSidebar())
  }

  render() {
    const { user, sidebar } = this.props
    const { location } = sidebar
    const header = (
      <Header>
        <HeaderImg src={bftLogo} alt="" />
      </Header>
    )
    return (
      <div>
        {header}
        <Divider />
        <List>
          {PAGES.filter(({ noSession }) => (user ? !noSession : noSession))
            .filter(({ municipality }) =>
              user && user.organization && user.organization.municipality
                ? municipality === null ||
                  typeof municipality === "undefined" ||
                  municipality
                : !municipality
            )
            .filter(({ show }) => (show ? user && show(user) : true))
            .map((p, i) => (
              <StyledListItem
                button
                onClick={this.makeGoToLink(p)}
                current={`${this.isActive(p, location.pathname)}`}
                data-test={p.testName}
                key={i}
              >
                <ListItemAvatar>
                  <Avatar>{p.icon}</Avatar>
                </ListItemAvatar>
                <ListItemText primary={p.name} />
              </StyledListItem>
            ))}
        </List>
      </div>
    )
  }
}

Sidebar.propTypes = {
  user: PropTypes.object,
  sidebar: PropTypes.object,
  dispatch: PropTypes.func
}

const mapStateToProps = createStructuredSelector({
  sidebar: makeSelectSidebar(),
  user: makeSelectUser()
})

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withReducer = injectReducer({
  key: "sidebar",
  reducer
})

export default compose(withReducer, withConnect, withApollo)(Sidebar)
