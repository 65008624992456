import { graphql } from "@apollo/client/react/hoc"
import gql from "graphql-tag"
import { userLoaded, userNotLoaded } from "common/actions"
import { useQuery } from "@apollo/client"

const QUERY = gql`
  query currentOrganizerQuery {
    currentOrganizer {
      id
      name
      email
      authToken
      roles
      organization {
        id
        name
        municipality
      }
    }
  }
`

const withUser = graphql(QUERY, {
  options: ({ dispatch }) => ({
    fetchPolicy: "cache",
    onCompleted: (r) => {
      const user = r.currentOrganizer
      if (user) {
        dispatch(userLoaded({ user }))
      } else {
        dispatch(userNotLoaded())
      }
    }
  }),
  props: ({ data: { loading, currentOrganizer } }) => ({
    userLoading: loading,
    user: currentOrganizer
  })
})

export const useOrganizer = () => {
  const { data } = useQuery(QUERY, {
    fetchPolicy: "cache-first"
  })
  return data.currentOrganizer
}

export default withUser
