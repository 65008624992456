type OrganizerRole =
  | "full"
  | "events"
  | "reports"
  | "sales"
  | "catering"
  | "order_monitoring"
  | "payment"

export const ROLE_OPTIONS: {
  value: OrganizerRole
  label: string
}[] = [
  {
    value: "full",
    label: "Admin (Full Access)"
  },
  {
    value: "events",
    label: "Event management"
  },
  {
    value: "reports",
    label: "Reports"
  },
  {
    value: "sales",
    label: "Shift sales"
  },
  {
    value: "catering",
    label: "Catering"
  },
  {
    value: "order_monitoring",
    label: "Order monitoring"
  },
  {
    value: "payment",
    label: "Payment setup"
  }
]

type Organizer = {
  roles: OrganizerRole[]
}

export const roleLabel = (role: OrganizerRole): string => {
  const found = ROLE_OPTIONS.find((r) => r.value === role)
  return found ? found.label : "unknown role"
}

export const hasFullAccess = (user: Organizer) =>
  user && user.roles.includes("full")

export const hasEventsAccess = (user: Organizer) =>
  user && (user.roles.includes("events") || hasFullAccess(user))

export const hasReportsAccess = (user: Organizer) =>
  user && (user.roles.includes("reports") || hasFullAccess(user))

export const hasSalesAccess = (user: Organizer) =>
  user && (user.roles.includes("sales") || hasFullAccess(user))

export const hasCateringAccess = (user: Organizer) =>
  user && (user.roles.includes("catering") || hasFullAccess(user))

export const hasOrderMonitoringAccess = (user: Organizer) =>
  user && (user.roles.includes("order_monitoring") || hasFullAccess(user))

export const hasPaymentAccess = (user: Organizer) =>
  user && (user.roles.includes("payment") || hasFullAccess(user))
