/**
 *
 * Navbar
 *
 */

import useMediaQuery from "@material-ui/core/useMediaQuery"
import makeSelectSidebar from "organizers/components/Sidebar/selectors"
import React from "react"
import ErrorBoundary from "common/components/ErrorBoundary"
import QuickSearch from "common/components/QuickSearch"
import { makeSelectUser } from "common/selectors"
import styled from "styled-components"
import { createStructuredSelector } from "reselect"
import { compose } from "redux"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import injectReducer from "organizers/utils/injectReducer"
import makeSelectNavbar from "./selectors"
import reducer from "./reducer"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import Hidden from "@material-ui/core/Hidden"
import { withStyles } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"
import Sidebar from "organizers/components/Sidebar"
import { closeSidebar, openSidebar } from "./actions"
import styles from "common/components/Navbar/navbarStyles"
import { withApollo } from "@apollo/client/react/hoc"
import QUICK_SEARCH_QUERY from "organizers/queries/quickSearchQuery"
const ToolbarItemsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`

const Filler = styled.div`
  flex: 1 1 auto;
`
export const NavbarContext = React.createContext({
  setHeaderComponent: null,
  setRightComponent: null
})

const HeaderWrapper = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    max-width: calc(100vw - 200px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`

const HeaderComponent = ({ headerComponent, showHeaderComponent }) => {
  const isMobile = useMediaQuery("(max-width:600px)")
  if (isMobile && !showHeaderComponent) return false
  return <HeaderWrapper>{headerComponent}</HeaderWrapper>
}

class Navbar extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      anchorEl: null,
      headerComponent: null,
      rightComponent: null,
      context: {
        setHeaderComponent: this.setHeaderComponent,
        setRightComponent: this.setRightComponent
      }
    }
  }

  handleDrawerToggle = () => {
    const { navbar } = this.props
    if (navbar.state.mobileOpen) {
      this.props.dispatch(closeSidebar())
    } else {
      this.props.dispatch(openSidebar())
    }
  }

  openDrawer = () => {
    this.props.dispatch(openSidebar())
  }

  closeDrawer = () => {
    this.props.dispatch(closeSidebar())
  }

  setHeaderComponent = (headerComponent) => {
    this.setState({
      headerComponent
    })
  }

  setRightComponent = (rightComponent) => {
    this.setState({
      rightComponent
    })
  }

  render() {
    const { classes, navbar, user } = this.props
    const drawer = <Sidebar />
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)
    const isMunicipality = user?.organization?.municipality
    return (
      <NavbarContext.Provider value={this.state.context}>
        <div className={classes.appFrame}>
          <AppBar
            className={classes.appBar}
            classes={{ root: classes.appBarRoot }}
          >
            <Toolbar>
              <ToolbarItemsWrapper>
                <IconButton
                  onClick={this.handleDrawerToggle}
                  className={classes.navIconHide}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <HeaderComponent
                  headerComponent={this.state.headerComponent}
                  showHeaderComponent={this.state.showHeaderComponent}
                />
                {user && !isMunicipality && (
                  <ErrorBoundary message="Error">
                    {() => (
                      <QuickSearch
                        onFocus={this.hideHeaderComponent}
                        onBlur={this.showHeaderComponent}
                        query={QUICK_SEARCH_QUERY}
                      />
                    )}
                  </ErrorBoundary>
                )}
                <Filler />
                {this.state.rightComponent}
              </ToolbarItemsWrapper>
            </Toolbar>
          </AppBar>
          <Hidden mdUp>
            <SwipeableDrawer
              disableBackdropTransition={!iOS}
              disableDiscovery={iOS}
              anchor="left"
              open={navbar.state.mobileOpen}
              classes={{
                paper: classes.drawerPaper
              }}
              onOpen={this.openDrawer}
              onClose={this.closeDrawer}
              ModalProps={{
                keepMounted: true // Better open performance on mobile.
              }}
            >
              {drawer}
            </SwipeableDrawer>
          </Hidden>
          <Hidden smDown implementation="css">
            <Drawer
              variant="permanent"
              open
              classes={{
                docked: classes.drawerDocked,
                paper: classes.drawerPaper
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <main id="page-main" className={classes.content}>
            {this.props.children}
          </main>
        </div>
      </NavbarContext.Provider>
    )
  }
}

Navbar.propTypes = {
  classes: PropTypes.object,
  navbar: PropTypes.object,
  sidebar: PropTypes.object,
  children: PropTypes.any,
  dispatch: PropTypes.func
}

const mapStateToProps = createStructuredSelector({
  navbar: makeSelectNavbar(),
  sidebar: makeSelectSidebar(),
  user: makeSelectUser()
})

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withReducer = injectReducer({
  key: "navbar",
  reducer
})

export default compose(
  withReducer,
  withConnect,
  withStyles(styles, { withTheme: true }),
  withApollo
)(Navbar)
