import { fromJS } from "immutable"
import {
  ENTITY_LOADED,
  MARKETS_LOADED,
  TRUCK_GROUPS_LOADED,
  INVALIDATE_ENTITY_CACHE
} from "./constants"

const initialState = fromJS({
  markets: null,
  truckGroups: null
})

function entitiesReducer(state = initialState, action) {
  switch (action.type) {
    case INVALIDATE_ENTITY_CACHE:
      return state.set(action.entityName, null)
    case ENTITY_LOADED:
      return state.setIn(action.storePath, fromJS(action.data))
    case MARKETS_LOADED:
      return state.set("markets", fromJS(action.markets))
    case TRUCK_GROUPS_LOADED:
      return state.set("truckGroups", fromJS(action.truckGroups))
    default:
      return state
  }
}

export default entitiesReducer
