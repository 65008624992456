/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import CateringPreferencesPage from "organizers/containers/CateringPreferencesPage/Loadable"
import CateringRequestPage from "organizers/containers/CateringRequestPage/Loadable"
import CateringRequestsPage from "organizers/containers/CateringRequestsPage/Loadable"
import NewCateringRequestPage from "organizers/containers/NewCateringRequestPage/Loadable"
import SupportConversationsPage from "organizers/containers/SupportConversationsPage/Loadable"
import ChatConversationsPage from "organizers/containers/ChatConversationsPage/Loadable"
import ChatConversationPage from "organizers/containers/ChatConversationPage/Loadable"
import CateringRequestOrdersPage from "organizers/containers/CateringRequestOrdersPage/Loadable"
import React from "react"
import { Switch, Route, withRouter, Redirect } from "react-router-dom"
import CssBaseline from "@material-ui/core/CssBaseline"
import styled from "styled-components"
import { connect } from "react-redux"
import { compose } from "redux"
import HomePage from "organizers/containers/HomePage/Loadable"
import SchedulePage from "organizers/containers/SchedulePage/Loadable"
import PaymentsPage from "organizers/containers/PaymentsPage/Loadable"
import NotFoundPage from "organizers/containers/NotFoundPage/Loadable"
import NotAuthorizedPage from "organizers/containers/NotAuthorizedPage/Loadable"
import LoginPage from "organizers/containers/LoginPage/Loadable"
import ForgotPasswordPage from "organizers/containers/ForgotPasswordPage/Loadable"
import ResetPasswordPage from "organizers/containers/ResetPasswordPage/Loadable"
import ErrorBoundary from "common/components/ErrorBoundary/index"
import Navbar from "organizers/components/Navbar"
import NotificationStack from "organizers/components/NotificationStack"
import BlockUIConnected from "organizers/components/BlockUIConnected"
import NewDebitCardPage from "organizers/containers/NewDebitCardPage/Loadable"
import SettingsPage from "organizers/containers/SettingsPage/Loadable"
import TruckPage from "organizers/containers/TruckPage/Loadable"
import AccountsPage from "organizers/containers/AccountsPage/Loadable"
import NewAccountPage from "organizers/containers/NewAccountPage/Loadable"
import EditAccountPage from "organizers/containers/EditAccountPage/Loadable"
import DefaultLotSettingsPage from "organizers/containers/DefaultLotSettingsPage/Loadable"
import OrganizationBookingRestrictionsPage from "organizers/containers/OrganizationBookingRestrictionsPage/Loadable"
import TruckGroupsPage from "organizers/containers/TruckGroupsPage/Loadable"
import NewTruckGroupPage from "organizers/containers/NewTruckGroupPage/Loadable"
import EditTruckGroupPage from "organizers/containers/EditTruckGroupPage/Loadable"
import LotsPage from "organizers/containers/LotsPage/Loadable"
import MassAutoCoverPage from "organizers/containers/MassAutoCoverPage/Loadable"
import NewLotPage from "organizers/containers/NewLotPage/Loadable"
import EditLotPage from "organizers/containers/EditLotPage/Loadable"
import LotPage from "organizers/containers/LotPage/Loadable"
import NewEventPage from "organizers/containers/NewEventPage/Loadable"
import EventPage from "organizers/containers/EventPage/Loadable"
import EditEventPage from "organizers/containers/EditEventPage/Loadable"
import ReportsPage from "organizers/containers/ReportsPage/Loadable"
import ReportPage from "organizers/containers/ReportPage/Loadable"
import LotSubscriptionsPage from "organizers/containers/LotSubscriptionsPage/Loadable"
import withUser from "organizers/queries/withUser"
import PageLoader from "common/components/PageLoader"
import queryString from "query-string"
import LotOrdersPage from "organizers/containers/LotOrdersPage/Loadable"
import OrderMonitoringPage from "organizers/containers/OrderMonitoringPage/Loadable"
import LotPostingsPage from "organizers/containers/LotPostingsPage/Loadable"
import ShiftSalesPage from "organizers/containers/ShiftSalesPage/Loadable"
import TruckInspectionsPage from "organizers/containers/TruckInspectionsPage/Loadable"
import TruckInspectionPage from "organizers/containers/TruckInspectionPage/Loadable"
import LocationStatsPage from "organizers/containers/LocationStatsPage/Loadable"
import {
  hasCateringAccess,
  hasEventsAccess,
  hasFullAccess,
  hasOrderMonitoringAccess,
  hasPaymentAccess,
  hasReportsAccess,
  hasSalesAccess
} from "../../organizerRoles"

const withRole = (Component, ...hasRole) =>
  hasRole.find((v) => v) ? Component : NotAuthorizedPage

const OuterBody = styled.main``

class App extends React.PureComponent {
  render() {
    const { userLoading, user } = this.props
    if (userLoading) return <PageLoader />

    const fullAccess = hasFullAccess(user)
    const eventsAccess = hasEventsAccess(user)
    const reportsAccess = hasReportsAccess(user)
    const salesAccess = hasSalesAccess(user)
    const cateringAccess = hasCateringAccess(user)
    const orderMonitoringAccess = hasOrderMonitoringAccess(user)
    const paymentAccess = hasPaymentAccess(user)
    const routes = user ? (
      <Switch>
        <Route
          path="/sign-in"
          render={(props) => (
            <Redirect to={queryString.parse(props.location.search).to || "/"} />
          )}
        />
        <Route exact path="/" component={HomePage} />
        <Route
          exact
          path="/mobile-inspections"
          component={TruckInspectionsPage}
        />
        <Route
          exact
          path="/mobile-inspections/:id"
          component={TruckInspectionPage}
        />
        <Route
          exact
          path="/shift-sales"
          component={withRole(ShiftSalesPage, salesAccess)}
        />
        <Route
          exact
          path="/monitoring"
          component={withRole(OrderMonitoringPage, orderMonitoringAccess)}
        />
        <Route
          exact
          path="/schedule"
          component={withRole(SchedulePage, eventsAccess)}
        />
        <Route
          exact
          path="/payments"
          component={withRole(PaymentsPage, paymentAccess)}
        />
        <Route
          exact
          path="/settings"
          component={withRole(SettingsPage, cateringAccess, eventsAccess)}
        />
        <Route
          exact
          path="/settings/lot-defaults"
          component={withRole(DefaultLotSettingsPage, eventsAccess)}
        />
        <Route
          exact
          path="/settings/users"
          component={withRole(AccountsPage, fullAccess)}
        />
        <Route
          exact
          path="/settings/users/new"
          component={withRole(NewAccountPage, fullAccess)}
        />
        <Route
          exact
          path="/settings/users/:id"
          component={withRole(EditAccountPage, fullAccess)}
        />
        <Route
          exact
          path="/settings/subscriptions"
          component={withRole(LotSubscriptionsPage, eventsAccess)}
        />
        <Route
          exact
          path="/settings/booking-restrictions"
          component={withRole(
            OrganizationBookingRestrictionsPage,
            eventsAccess
          )}
        />
        <Route
          exact
          path="/settings/truck-groups"
          component={withRole(TruckGroupsPage, eventsAccess, cateringAccess)}
        />
        <Route
          exact
          path="/settings/truck-groups/new"
          component={withRole(NewTruckGroupPage, eventsAccess, cateringAccess)}
        />
        <Route
          exact
          path="/settings/truck-groups/:id"
          component={withRole(EditTruckGroupPage, eventsAccess, cateringAccess)}
        />
        <Route
          exact
          path="/settings/catering-preferences"
          component={withRole(CateringPreferencesPage, cateringAccess)}
        />
        <Route
          exact
          path="/catering-requests"
          component={withRole(CateringRequestsPage, cateringAccess)}
        />
        <Route
          exact
          path="/catering-requests/new"
          component={withRole(NewCateringRequestPage, cateringAccess)}
        />
        <Route
          exact
          path="/catering-requests/:id/orders"
          component={withRole(CateringRequestOrdersPage, cateringAccess)}
        />
        <Route
          exact
          path="/catering-requests/:id"
          component={withRole(CateringRequestPage, cateringAccess)}
        />
        <Route
          exact
          path="/support-conversations"
          component={withRole(SupportConversationsPage, cateringAccess)}
        />
        <Route
          exact
          path="/chat-conversations"
          component={withRole(ChatConversationsPage, cateringAccess)}
        />
        <Route
          exact
          path="/chat-conversations/:id"
          component={withRole(ChatConversationPage, cateringAccess)}
        />
        <Route
          exact
          path="/payments/new-debit-card"
          component={withRole(NewDebitCardPage, paymentAccess)}
        />
        <Route
          exact
          path="/locations/:id/stats"
          component={withRole(
            LocationStatsPage,
            eventsAccess,
            orderMonitoringAccess
          )}
        />
        <Route
          exact
          path="/lots"
          component={withRole(LotsPage, eventsAccess)}
        />
        <Route
          exact
          path="/lots/lot-postings"
          component={withRole(LotPostingsPage, eventsAccess)}
        />
        <Route
          exact
          path="/mass-auto-cover"
          component={withRole(MassAutoCoverPage, eventsAccess)}
        />
        <Route
          exact
          path="/lots/new"
          component={withRole(NewLotPage, eventsAccess)}
        />
        <Route
          exact
          path="/lots/:lotId/order-delivery"
          component={withRole(LotOrdersPage, orderMonitoringAccess)}
        />
        <Route
          exact
          path="/lots/:lotId/events/new"
          component={withRole(NewEventPage, eventsAccess)}
        />
        <Route
          exact
          path="/lots/:lotId/events/:id"
          component={withRole(EventPage, eventsAccess)}
        />
        <Route
          exact
          path="/lots/:lotId/events/:id/edit"
          component={withRole(EditEventPage, eventsAccess)}
        />
        <Route
          exact
          path="/lots/:id/edit"
          component={withRole(EditLotPage, eventsAccess)}
        />
        <Route
          exact
          path="/lots/:id"
          component={withRole(LotPage, eventsAccess)}
        />
        <Route exact path="/trucks/:id" component={TruckPage} />
        <Route
          exact
          path="/reports"
          component={withRole(ReportsPage, reportsAccess)}
        />
        <Route
          exact
          path="/reports/:reportUrl"
          component={withRole(ReportPage, reportsAccess)}
        />
        <Route exact path="/forgot-password" component={ForgotPasswordPage} />
        <Route exact path="/reset-password" component={ResetPasswordPage} />
        <Route component={NotFoundPage} />
      </Switch>
    ) : (
      <Switch>
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/forgot-password" component={ForgotPasswordPage} />
        <Route exact path="/reset-password" component={ResetPasswordPage} />
        <Redirect to="/" path="/sign-in" />
        <Route
          render={(props) => {
            const redirectTo = props.location.pathname
            return (
              <Redirect
                to={`/login${
                  redirectTo &&
                  !redirectTo.includes("/login") &&
                  redirectTo !== "/"
                    ? `?redirectTo=${encodeURI(redirectTo)}`
                    : ""
                }`}
              />
            )
          }}
        />
      </Switch>
    )
    return (
      <OuterBody>
        <CssBaseline />
        <Navbar>
          <ErrorBoundary message="Error Displaying the page. Try reloading the browser.">
            {routes}
          </ErrorBoundary>
        </Navbar>
        <BlockUIConnected />
        <NotificationStack />
      </OuterBody>
    )
  }
}

const withConnect = connect()

export default compose(withRouter, withConnect, withUser)(App)
